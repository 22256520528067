<script setup>
import { computed } from 'vue'
import { t } from '@/i18n'
import { loginShowRef, loginModel } from '@/model/user'
import { appNameRef } from '@/model/basic'
import WiPopup from '@/components/WiPopup.vue'

const { phoneCodeRef, dataRef, loginFunc } = loginModel()

const appNameArr = computed(()=> {
    return appNameRef.value.split(' ')
})
</script>

<template>
    <WiPopup v-model="loginShowRef" center width="420px">
        <div class="w-full bg-default text-default rounded-xl" style="box-shadow: 0px 0px 0px 7px rgba(255,255,255,0.3);">
            <div class="p-4 lg:px-8 flex items-center">
                <div class="text-minor flex-1 overflow-hidden">
                    <!-- <svg class="w-4 h-4 inline-block align-middle" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M4 15H6V20H18V4H6V9H4V3C4 2.44772 4.44772 2 5 2H19C19.5523 2 20 2.44772 20 3V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V15ZM10 11V8L15 12L10 16V13H2V11H10Z"></path>
                    </svg>
                    <b class="ml-2 text-sm align-middle inline-block">{{t('立即登录')}}</b> -->
                    <h6 class="text-xl font-bold">
                        <span class="mr-2 inline-block">{{t('登录')}}</span>
                        <template v-for="(item,index) in appNameArr" :key="index">
                            <span :class="index==0?'text-minor':'text-third'" class="mr-1 font-mont inline-block">{{item}}</span>
                        </template>
                    </h6>
                </div>
                <button @click="loginShowRef = false" class="w-8 h-8 ctx-minor rounded-full flex items-center justify-center">
                    <svg class="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"></path>
                    </svg>
                </button>
            </div>
            <div class="p-4 lg:px-8">
                <dl class="w-full pb-5 block">
                    <dt class="mb-3 text-sm block">{{t('用户名')}}<span class="text-third">*</span></dt>
                    <dd class="w-full h-11 block">
                        <input type="text" v-model="dataRef.username" class="w-full h-full px-4 text-base bg-slate-100 focus:bg-transparent rounded border border-default outline-none">
                    </dd>
                </dl>
                <dl class="w-full pb-5 block">
                    <dt class="mb-3 text-sm block">{{t('登录密码')}}<span class="text-third">*</span></dt>
                    <dd class="w-full h-11 block">
                        <input type="password" v-model="dataRef.password" class="w-full h-full px-4 text-base bg-slate-100 focus:bg-transparent rounded border border-default outline-none">
                    </dd>
                </dl>
                <button @click="loginFunc()" class="px-9 h-10 max-w-full ctx-theme text-sm rounded font-medium">
                    <span>{{t('登录')}}</span>
                </button>
                <div class="w-full h-5"></div>
            </div>
        </div>
    </WiPopup>
</template>