import { ref } from "vue"

var EventUtil = {
	//Notice: type is not include 'on', for example: click mouseover mouseout and so on
	addHandler: function(element, type, handler) {
		if (element.addEventListener) {
			element.addEventListener(type, handler, false)
		} else if (element.attachEvent) {
			element.attachEvent('on' + type, handler)
		} else {
			element['on' + type] = handler
		}
	},

	preventDefault: function(event) {
		if (event.preventDefault) {
			event.preventDefault()
		} else {
			event.returnValue = false
		}
	}
}

export const mediaTypeRef = ref('xs')

export function initMediaFunc() {
    funcOutputSize()
    EventUtil.addHandler(window, "resize", function() {
        funcOutputSize()
    })
}

function funcOutputSize() {
    let xl2 = window.matchMedia('(min-width:1536px)')
    let xl = window.matchMedia('(min-width:1280px)')
    let lg = window.matchMedia('(min-width:1024px)')
    let md = window.matchMedia('(min-width:768px)')
    let sm = window.matchMedia('(min-width:640px)')
    if (xl2.matches) {
        mediaTypeRef.value = '2xl'
    } else if (xl.matches) {
        mediaTypeRef.value = 'xl'
    } else if (lg.matches) {
        mediaTypeRef.value = 'lg'
    } else if (md.matches) {
        mediaTypeRef.value = 'md'
    } else if (sm.matches) {
        mediaTypeRef.value = 'sm'
    } else {
        mediaTypeRef.value = 'xs'
    }
}