import { ref } from 'vue'

const promise = ref(null)
const resolve = ref(null)
const reject = ref(null)
export var visibleRef = ref(false)
export var dataRef = ref({
    title: '',
    content: '',
    noCancel: false,
    noConfirm: false,
    textCancel: '',
    textConfirm: ''
})
export function dialog(options) {
    if(visibleRef.value) return
    if (typeof options === 'string') {
        dataRef.value.content = options
    }else {
        dataRef.value.title = options.title
        dataRef.value.content = options.content
        dataRef.value.noCancel = options.noCancel
        dataRef.value.noConfirm = options.noConfirm
        dataRef.value.textCancel = options.textCancel
        dataRef.value.textConfirm = options.textConfirm
    }
    visibleRef.value = true
    promise.value = new Promise((res, rej)=> {
        resolve.value = res
        reject.value = rej
    })
    return promise.value
}
export function confirm() {
    visibleRef.value = false
    resolve.value()
}
export function cancel() {
    visibleRef.value = false
    // reject.value()
}