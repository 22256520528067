<script setup>
import { nextTick, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { scrollToTop } from '@/common/core'
import { initMediaFunc } from '@/model/media'
import { isAuthRef } from '@/model/user'
import UiHeader from "./components/UiHeader.vue"
import UiFooter from "./components/UiFooter.vue"
import WiToast from "./components/WiToast/toast.vue"
import WiDialog from "./components/WiDialog/dialog.vue"

const route = useRoute()
const router = useRouter()

const APPNAME = process.env.VUE_APP_NAME

watch(()=> isAuthRef.value, (newx)=> {
  if(!newx && route.meta.loginRequired) {
    router.replace('/')
  }
}, {immediate: true, deep: true})

watch(()=> route.path, ()=> {
  scrollToTop()
})

onMounted(()=> {
  nextTick(()=> {
    initMediaFunc()
    document.title = APPNAME
  })
})
</script>

<template>
  <div>
    <UiHeader></UiHeader>
    <router-view />
    <UiFooter></UiFooter>
  </div>
  <WiDialog></WiDialog>
  <WiToast></WiToast>
</template>