<script setup>
import { computed, defineEmits, defineProps, nextTick, ref, watch } from "vue"
import { classList } from '@/common/core'

const props = defineProps({
    modelValue: Boolean,
    width: String,
    top: Boolean,
    bottom: Boolean,
    left: Boolean,
    right: Boolean,
    center: Boolean,
    disableMaskClose: Boolean,
    maskColor: {
        type: String,
        default: 'black'
    },
    maskOpacity: {
        type: [Number,String],
        default: 0.8
    }
})

let visibleSync = ref(false)
let showDrawer = ref(false)

const emit = defineEmits(['update:modelValue', 'open', 'close'])

const showValue = computed({
  get() {
    return props.modelValue
  },
  set(_val) {
    emit('update:modelValue', _val)
  }
})

watch(showValue, (newX) => {
    if(newX) {
        visibleSync.value = newX;
        nextTick(()=> {
            open()
        })
    }else {
        close();
    }
}, {immediate: true})

const boxStyle = computed(()=> {
    let classname = '';
    if(props.left) {
        classname = 'transform left-0 top-0 h-full';
    }else if(props.right) {
        classname = 'transform right-0 top-0 h-full';
    }else if(props.top) {
        classname = 'transform top-0';
    }else if(props.bottom) {
        classname = 'transform bottom-0';
    }else {
        classname = 'transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 origin-center'
    }
    return classname;
})

const animateStyle = computed(()=> {
    let classname = '';
    if(props.left) {
        classname = showDrawer.value?'translate-x-0':'-translate-x-full';
    }else if(props.right) {
        classname = showDrawer.value?'translate-x-0':'translate-x-full';
    }else if(props.top) {
        classname = showDrawer.value?'translate-y-0':'-translate-y-full';
    }else if(props.bottom) {
        classname = showDrawer.value?'translate-y-0':'translate-y-full';
    }else {
        classname = showDrawer.value?'scale-100':'scale-0';
    }
    return classname;
})

function open() {
    classList(document.getElementsByTagName("body")[0],'overflow-hidden','add');
    setTimeout(() => {
        showDrawer.value = true;
        emit('open');
    }, 100);
}
function close() {
    showDrawer.value = false;
    setTimeout(() => {
        visibleSync.value = false;
        emit('update:modelValue',false);
        emit('close');
        classList(document.getElementsByTagName("body")[0],'overflow-hidden','remove');
    }, 200);
}
function clickMask() {
    if(!props.disableMaskClose) {
        close();
    }
}
</script>

<template>
    <div v-if="visibleSync" class="fixed top-0 left-0 w-full h-full" style="z-index: 88888;">
        <div @click="clickMask()" :style="'opacity: '+maskOpacity+';background-color: '+maskColor+';'" class="w-full h-full absolute top-0 left-0" style="z-index: 1;"></div>
        <div :class="boxStyle+' '+animateStyle" :style="{width: width||'300px',zIndex: 2,maxWidth: 'calc(100% - 32px)'}" class="max-h-full overflow-y-auto absolute transition-all duration-200 ease-in">
            <slot></slot>
        </div>
    </div>
</template>