import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/membership',
    name: 'membership',
    component: () => import('../views/MembershipView.vue')
  },
  {
    path: '/referral_program',
    name: 'referral_program',
    component: () => import('../views/ReferralProgramView.vue')
  },
  {
    path: '/FAQs',
    name: 'FAQs',
    component: () => import('../views/FAQsView.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/TermsView.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/AgreementView.vue')
  },
  {
    path: '/user',
    rediect: '/profile',
    component: () => import('../views/UserView.vue'),
    children: [
      {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/ProfileView.vue'),
        meta: {
          loginRequired: true
        }
      },
      {
        path: '/referral',
        name: 'referral',
        component: () => import('../views/ReferralView.vue'),
        meta: {
          loginRequired: true
        }
      },
      {
        path: '/withdraw',
        name: 'withdraw',
        component: () => import('../views/WithdrawView.vue'),
        meta: {
          loginRequired: true
        }
      },
      {
        path: '/recharge',
        name: 'recharge',
        component: () => import('../views/RechargeView.vue'),
        meta: {
          loginRequired: true
        }
      },
      {
        path: '/withdraw_account',
        name: 'withdraw_account',
        component: () => import('../views/WithdrawAccountView.vue'),
        meta: {
          loginRequired: true
        }
      },
      {
        path: '/reserve_journey',
        name: 'reserve_journey',
        component: () => import('../views/SearchHotelView.vue'),
        meta: {
          loginRequired: true
        }
      },
      {
        path: '/reservation_history',
        name: 'reservation_history',
        component: () => import('../views/HotelHistoryView.vue'),
        meta: {
          loginRequired: true
        }
      },
      {
        path: '/event',
        name: 'event',
        component: () => import('../views/EventView.vue'),
        meta: {
          loginRequired: true
        }
      },
      {
        path: '/set_paypwd',
        name: 'set_paypwd',
        component: () => import('../views/SetPaypwdView.vue'),
        meta: {
          loginRequired: true
        }
      },
    ]
  },
  {
    path: '/regular_reservation',
    name: 'regular_reservation',
    component: () => import('../views/BookPrepView.vue'),
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/book_view',
    name: 'book_view',
    component: () => import('../views/BookViewView.vue'),
    meta: {
      loginRequired: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView.vue'),
    meta: {}
  },
  {
    path: '/country_detail',
    name: 'country_detail',
    component: () => import('../views/CountryDetailView.vue'),
    meta: {}
  },
  {
    path: '/empty',
    name: 'empty',
    component: () => import('../views/EmptyView.vue'),
    meta: {}
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
