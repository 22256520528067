import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'vue3-carousel/dist/carousel.css'
import './main.css'

// UI
import IconAdd from './components/IconAdd.vue'
import IconArrowDownS from './components/IconArrowDownS.vue'
import IconArrowRightS from './components/IconArrowRightS.vue'
import IconArrowUpS from './components/IconArrowUpS.vue'
import IconChevronRight from './components/IconChevronRight.vue'
import IconMapPin from './components/IconMapPin.vue'
import IconStar from './components/IconStar.vue'
import IconTap from './components/IconTap.vue'
import UiGuideHeader from './components/UiGuideHeader.vue'
import WiImage from './components/WiImage.vue'

const app = createApp(App)

app.component('IconAdd', IconAdd)
app.component('IconArrowDownS', IconArrowDownS)
app.component('IconArrowRightS', IconArrowRightS)
app.component('IconArrowUpS', IconArrowUpS)
app.component('IconChevronRight', IconChevronRight)
app.component('IconMapPin', IconMapPin)
app.component('IconStar', IconStar)
app.component('IconTap', IconTap)
app.component('UiGuideHeader', UiGuideHeader)
app.component('WiImage', WiImage)

app.use(router).mount('#app')
