<script setup>
import { defineEmits, defineProps, ref } from 'vue'

const emit = defineEmits(['load', 'error'])

const props = defineProps({
    src: String,
    fit: {
        type: String,
        default: 'contain',
        //['contain','cover','fill','none']
    },
    autoW: Boolean,
    autoH: Boolean,
    emptyStyle: String,
})

let loading = ref(true)
let error = ref(false)

function onLoad(e) {
    loading.value = false;
    error.value = false;
    emit('load',e);
}
function onError(e) {
    loading.value = false;
    error.value = true;
    emit('error',e);
}
</script>

<template>
    <div class="relative">
        <img @load="onLoad" @error="onError" :src="src" :class="{
            'w-full': !autoW,
            'h-full': !autoH,
            'w-auto': autoW,
            'h-auto': autoH,
            'object-contain': fit == 'contain',
            'object-cover': fit == 'cover',
            'object-fill': fit == 'fill',
            'object-top': fit == 'top',
            'opacity-0': loading || (!loading && error)
        }" class="max-h-full">
        <div v-if="loading" :style="emptyStyle" class="w-full h-full max-w-full max-h-full bg-slate-100 text-slate-300 absolute left-0 top-0 text-center align-middle">
			<svg fill="currentColor" class="inline-block align-middle" style="width: 60%;height: 60%;max-width: 32px;max-height: 32px;" width="32" height="32" viewBox="0 0 1338 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
				<path d="M314.557637 421.660423a121.512979 121.512979 0 0 0 93.69871-40.147317 129.399639 129.399639 0 0 0 0-187.39742 132.515356 132.515356 0 1 0-187.39742 187.39742 132.125891 132.125891 0 0 0 93.69871 40.147317z m0 0z m843.28839 133.846027l-287.78194-287.78194-354.704954 354.737409-113.788596-113.788596-220.858927 220.858926v133.846027h977.134417v-307.871826z m0 0zM1271.602168 0H66.923014A71.986054 71.986054 0 0 0 0 66.923014v890.153972a71.986054 71.986054 0 0 0 66.923014 66.923014h1204.711609a71.986054 71.986054 0 0 0 66.923014-66.923014V66.923014A67.052835 67.052835 0 0 0 1271.634623 0z m0 950.391176H66.923014V66.923014h1204.711609v883.468162z m0.032455 0z"></path>
			</svg>
            <i class="w-0 h-full inline-block align-middle"></i>
		</div>
        <div v-if="error" :style="emptyStyle" class="w-full h-full bg-slate-100 text-slate-300 absolute left-0 top-0 text-center align-middle">
			<svg fill="currentColor" class="inline-block align-middle" style="width: 60%;height: 60%;max-width: 32px;max-height: 32px;" width="32" height="32" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
				<path d="M561.27 635.074c9.52-10.288 9.016-25.584-1.164-35.318l-141.48-139.12-45.206-41.748c-2.326 1.076-3.49 3.204-5.818 5.356L74.22 751.716V193.112c2.328-14.992 15.078-24.628 30.154-24.628h400.058L569.39 100H64.936C28.99 100 0 126.758 0 161v702C0 896.192 28.99 924 64.936 924h224.982l271.352-288.926z m82.388-294.74c0 36.798 19.516 70.8 51.2 89.2a101.884 101.884 0 0 0 102.4 0c31.68-18.4 51.2-52.402 51.2-89.2 0-56.886-45.846-103-102.4-103-56.554 0-102.4 46.114-102.4 103z"></path>
				<path d="M957.834 100H736.886c-1.18 2.152-2.364 5.356-4.726 6.432L673.08 168.666h244.58c16.54 0 29.538 10.736 30.72 25.75l1.18 558.99-236.306-229.596c-7.734-8.25-18.952-12.956-30.72-12.888-11.816 0-20.086 5.38-30.72 11.812l-10.634 7.506 69.71 67.592c10.634 9.66 10.634 24.674 1.18 35.408L433.234 924l524.6-0.002c17.534 0.16 34.402-6.222 46.838-17.716 12.434-11.494 19.396-27.14 19.33-43.444V160.084C1024 126.824 994.46 100 957.834 100z"></path>
			</svg>
            <i class="w-0 h-full inline-block align-middle"></i>
		</div>
    </div>
</template>