import { computed, onMounted, ref } from "vue";
import { t } from '@/i18n'
import { useLocalStorage } from '@vueuse/core'
import { get, post } from '../request'
import { useRoute, useRouter } from "vue-router";
import { dialog } from "@/components/WiDialog";
import { passwordCore, isSameCore } from '@/common/core'



export const authRef = useLocalStorage('authInfo', {})
export const isAuthRef = computed(() => !!authRef.value.access_token)
export const memberLocal = useLocalStorage('member', {})
const accountLocal = computed(()=> {
    return memberLocal.value&&memberLocal.value.account||{}
})

export var loginShowRef = ref(false)
export var registerShowRef = ref(false)

export function userModel() {
    const memberRef = computed(()=> { return memberLocal.value||{} })
    const accountRef = computed(()=> { return accountLocal.value||{} })
    const currentLevel = computed(()=> {
        return memberLocal.value&&memberLocal.value.memberLevel||{}
    })
    async function userInfoFunc() {
        let res = await get({url: '/v1/member/member/info'})
        if(res.code != 200) return
        memberLocal.value = res.data&&res.data.member
    }

    onMounted(()=> {
        userInfoFunc()
    })
    return { memberRef, accountRef, currentLevel, userInfoFunc }
}

export function signConfigModel() {
    const configRef = ref({})
    const phoneCodeRef = ref({})

    const refresh = async () => {
        const res = await get({ url: '/v1/site/register-config' }, { loading: true })
        configRef.value = res.data || {}
        phoneCodeRef.value = (configRef.value.mobile_preg_list || [])[0] || {}
    }

    onMounted(() => {
        refresh()
    })

    return { configRef, phoneCodeRef }
}

export function loginModel() {
    const { phoneCodeRef } = signConfigModel()

    let dataRef = ref({username: '', password: ''})

    async function loginFunc() {
        const data = {...dataRef.value, group: 'h5', mobile_code: phoneCodeRef.value&&phoneCodeRef.value.code}
        const res = await post({url: '/v1/site/login', data: data}, {loading: true, toast: true})
        if(res.code != 200) return
        
        loginShowRef.value = false
        authRef.value = res.data
        memberLocal.value = res.data&&res.data.member
        dataRef.value.username = ''
        dataRef.value.password = ''
    }

    return { phoneCodeRef, dataRef, loginFunc }
}

export function logoutFunc() {
    dialog({title: t('提示'), content: t('你确定要登出当前账号吗？')}).then(()=> {
        _logout()
    })
}

function _logout() {
    authRef.value = {}
    memberLocal.value = {}
}

export function registerModel() {
    const { phoneCodeRef } = signConfigModel()
    const route = useRoute()

    let dataRef = ref({
        mobile: '', 
        password: '', 
        password_repetition: '', 
        invitation_code: route.query.invitation_code||'', 
    })

    async function registerFunc() {
        // registerShowRef.value = false
        // loginShowRef.value = true
        const data = {...dataRef.value, group: 'h5', mobile_code: phoneCodeRef.value&&phoneCodeRef.value.code }
        const res = await post({url: '/v1/site/register', data: data}, {loading: true, toast: true})
        if(res.code != 200) return
        registerShowRef.value = false
        loginShowRef.value = true
        // if(res.data&&res.data.access_token) {
        //     authRef.value = res.data
        //     memberLocal.value = res.data&&res.data.member
        // }
        dataRef.value.mobile = ''
        dataRef.value.password = ''
        dataRef.value.password_repetition = ''
        dataRef.value.invitation_code = ''
    }

    return { phoneCodeRef, dataRef, registerFunc }
}

export function changePassModel() {
    const router = useRouter()
    var dataRef = ref({
        old_password: '',
        password: '',
        password_repetition: '',
    })
    async function cheangeLoginFunc(type) {
        dataRef.value.group = 'h5'
        dataRef.value.type = type
        if(type=='pay_pwd') {
            if(memberLocal.value&&memberLocal.value.have_password_security == 1) {
                if(!passwordCore(dataRef.value.old_password, t('请输入原提现密码'))) return
            }
            if(!passwordCore(dataRef.value.password, t('请输入新提现密码'))) return
            if(!isSameCore(dataRef.value.password, dataRef.value.password_repetition, t('确认提现密码与新提现密码不相同'))) return
        }else {
            if(!passwordCore(dataRef.value.old_password, t('请输入原登录密码'))) return
            if(!passwordCore(dataRef.value.password, t('请输入新登录密码'))) return
            if(!isSameCore(dataRef.value.password, dataRef.value.password_repetition, t('确认登录密码与新登录密码不相同'))) return
        }
        const res = await post({ url: '/v1/site/modify-pwd', data: dataRef.value }, { loading: true, toast: true })
        if (res.code != 200) return

        if(type == 'login_pwd') {
            _logout()
            router.replace('/')
        }else {
            router.back()
        }
    }
    return { dataRef, cheangeLoginFunc }
}