export default {
    "title": "🇺🇸 English",

    "首页": "Home",
    "关于": "About",
    "会员": "Membership",
    "推荐计划": "Referral Program",
    "个人": "Profile",
    "推荐": "Referral",
    "预订": "Reserve",
    "事件": "Event",
    "登出": "Logout",
    "登录": "Sign In",
    "注册": "Sign Up",
    "确认": "Confirm",
    "升级会员请联系客服": "Please contact customer service for upgrading membership",
    "我们的应用程序现在可用": "Stay Tuned!",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "For greater experience, we have just launced our mobile apps for seamless and faster booking.",
    "iOS版下载（即将推出）": "Download for iOS (Coming Soon)",
    "Android版下载（即将推出）": "Download for Android(Coming Soon)",
    "为什么选择我们": "Why Choose Us",
    "我们卓越的服务。": "Our outstanding services.",
    "收入最高的酒店": "Top Traveler's Attractions",
    "我们的合作酒店收入最高。": "The perfect sanctuary for our partnership!",
    "回报": "Rewards",
    "最具特色的酒店": "Most Featured Attractions",
    "我们平台上最有特色的酒店。": "Most featured hotel in our platform.",
    "热门国家和目的地": "Top Countries & Destination",
    "全球各住宿类型及酒店业务最佳合作。": "Best cooperation in various accommodation types and hotel business around the world.",
    "酒店": "Attractions",
    "预订酒店并开始赚取": "Explore the world with us!",
    "准备好与我们一起探索酒店的世界！": "Get ready to explore the world of Attractions with us!",
    "客户评价": "Testimonials",
    "查看我们用户的真实评价。": "Check our authentic testimonials from our users.",
    "银行卡列表": "Bank Card List",
    "银行列表": "Bank List",
    "持卡人姓名": "Cardholder name",
    "银行卡号": "Bank Accound",
    "支付密码": "Withdraw Password",
    "提交": "Submit",
    "Pix列表": "Pix List",
    "Pix类型": "Pix Type",
    "Pix账号": "Pix Accound",
    "账号列表": "Accound List",
    "网络": "Network",
    "地址": "Address",
    "备注": "Remark",
    "充值金额": "Recharge Amount",
    "支付类型": "Pay Type",
    "支付银行": "Pay Bank",
    "USDT地址": "Wallet Address",
    "二维码": "QR Code",
    "充值渠道": "Recharge Channel",
    "充值凭证": "Recharge Voucher",
    "立即登录": "Login Now",
    "用户名": "Phone Number",
    "密码": "Password",
    "邀请码": "Referral Code",
    "确认密码": "Confirm Password",
    "手机": "Phone Number",
    "注册2": "Sign Up",
    "金额": "Amount",
    "新增": "New",
    "我们的精彩": "Our Awesome",
    "故事": "Story",
    "奖项": "Awards",
    "获得": "Gained",
    "钱包余额": "Assets Balance",
    "今日奖励": "Today's Rewards",
    "总行程": "Total Reservation",
    "访问行程": "Visited Journeys",
    "酒店历史": "Reservation History",
    "单价": "Price",
    "数量": "Number",
    "总额": "Total",
    "奖励": "Rewards",
    "上一页": "Prev",
    "下一页": "Next",
    "每套预订量": "Bookings Per Set",
    "预订奖励": "Reservation Reward",
    "每日预订1": "Per set of reservation is",
    "每日预订2": "Journeys",
    "手机号码": "Phone Number",
    "修改密码": "Change Password",
    "登录密码": "Sign In Password",
    "当前密码": "Old Password",
    "新密码": "New Password",
    "确认新密码": "Confirm New Password",
    "保存修改": "Update",
    "充值": "Top Up",
    "我们的": "Our",
    "复制": "Copy",
    "分享链接": "Share Link",
    "搜索酒店": "Reserve Journey",
    "实际等级": "Actual Level",
    "升级": "Upgrade",
    "已完成预订": "Reservation Completed",
    "登录时间": "Login Time",
    "信誉分": "Credibility",
    "控制菜单": "Dashboard Menu",
    "提现": "Withdraw",
    "提现账号": "Add Withdrawal Information",
    "你确定要删除此账号吗": "Are you sure delete this account",
    "你确定要删除此地址吗": "Are you sure delete this address",
    "提示": "Tips",
    "是": "Yes",
    "否": "No",
    "全部": "All",
    "待处理": "Pending",
    "冻结中": "Freezed",
    "已完成": "Completed",
    "订阅": "Subscribe",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "Want to be notified when we launch a new template or an udpate. Just sign up and we'll send you a notification by email.",
    "我们的伙伴": "Our partners",
    "关于我们": "ABOUT US",
    "实时聊天支持": "Agent Support",
    "在线聊天": "Online Chat",
    "创建时间": "Created Time",
    "你确定要登出当前账号吗？": "Do you want to log out?",
    "请输入你的邮箱": "Enter Your Email",
    "好评": "Very Good",
    "条评论": "Reviews",
    "平均每晚": "Avg/Night",
    "旅行旅程": "Journey Recommendation",
    "正在寻找酒店": "Looking for a journey",
    "开始预订": "Start Reservation",
    "旅程价格": "Journey Prices",
    "佣金": "Commissions",
    "底部-about": "is an international online publishing company headquartered in Paris, France, with offices in the US, Brazil, Lebanon, Saudi Arabia and Slovakia, specialized in producing executive interactive business reviews by country analyzing trends, featuring sector analysis, interviews, companies, videos and top stories.",
    "立即预订": "Reserve now",
    "搜索": "Search",
    "快乐的客户": "Happy Client",
    "快乐的酒店老板": "Happy Hotel Owner",
    "评论1": "Amazing platform with great feature, really worth to explore!",
    "评论2": "Proactive customer service that will guide you through if you unclear about the platform.",
    "评论3": "Trusted platform, worth a try!",
    "评论4": "Manage to list my hotel here and achieve great marketing result.",
    "推荐计划1": "Platform users can invite others to become platform agents through referral codes and become your downline.",
    "推荐计划2": "As the upline, you can extract a certain percentage of your downline’s profit, and the profit obtained by the upline will be directly returned to the upline’s platform account, or to the team report.",
    "推荐计划3": "Note: All agents / downlines of the platform will receive a certain percentage of the platform’s profits and rewards accordingly, developing a team does not affect the profits and rewards of any agent or downlines.",
    "评分": "Rating",
    "评论": "Comment",
    "我觉得选择这次旅行是一个非常明智的决定！": "I think it was a very wise decision to choose this journey!",
    "第一次体验很棒，下次还会选择这里！": "Great first experience, would choose this place again next time!",
    "你们非常配合，让我很舒服很开心！": "You guys have been very cooperative, which makes me so comfortable and happy!",
    "感谢您为我带来了一次美妙的旅程！": "Thanks for making a fantastic journey for me!",
    "服务很好，对我很有耐心！": "Excellent service, very patient with me!",
    "你还没有设置支付密码，请前往设置": "Please set your withdrawal password before make withdraw",
    "订单": "Orders",
    "确认登录密码": "Confirm Sign In Password",
    "我已阅读并同意": "I have read and agree",
    "注册协议": "Registration Agreement",
    "条约与条款": "Terms & Conditions",
    "客服": "Agent Support",
    "地址列表": "Address List",
    "国际旅程推荐": "International Journeys Recommendation",
    "您已成功订阅，您将在邮件中收到我们的最新动态！": "You have successfully subscribed, you will get our latest news in Email!",
    "请输入原提现密码": "Please enter the original withdrawal password",
    "请输入新提现密码": "Please enter the new withdrawal password",
    "确认提现密码与新提现密码不相同": "Confirm withdrawal password is not the same as the new withdrawal password",
    "请输入原登录密码": "Please enter the original login password",
    "请输入新登录密码": "Please enter new login password",
    "确认登录密码与新登录密码不相同": "Confirm login password is not the same as the new login password",
    "请输入提现密码": "Please enter the withdrawal password",
    "知道了": "Sure",
    "冻结金额": "In-Process Amount",
    '这是一个适合散步的好地方，狭窄的街道，建筑物上有一些可爱的建筑。':'This is a good place for a walk, with narrow streets and some lovely architecture on the buildings.',
    '海滩和中央大道之间的美丽广场。':'Beautiful square between the beach and Central Avenue.',
    '非常漂亮的建筑，历史很有趣。':'Very beautiful building with interesting history.',
    '这里有很多餐厅可供选择，氛围非常轻松。':'There are many restaurants to choose from here, with a very relaxed atmosphere.',
    '美丽的土地，漂亮的橘园和动物。 周末度假的绝佳去处。 有趣的地方...':'Beautiful land, lovely orange groves and animals. A great place for a weekend getaway. Interesting place...',
    '一个坐下来观看世界过去的好地方。':'A great place to sit down and watch the world go by.',
    '高级海滩俱乐部':'High-end beach club',
    '这是对在战争中阵亡的人们的一个可爱的纪念。':'This is a lovely memorial to those who died in the war.',
    '这是一个闲逛的好地方，有很多值得一看的地方......':'This is a great place to wander around, with many places worth seeing...',
    '非常适合度假、放松的好地方！':'A great place for vacation and relaxation!',
    '第一次来，很温馨，下次还会再来的。':'First time here, very cozy, will come again next time.',
    '这是一种不同的体验，感觉很棒':'This is a different experience and it feels great',
    '当地人都很热情，很有礼貌！':'The locals are very friendly and polite!',
}